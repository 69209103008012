<template>
  <v-dialog
    persistent
    max-width="500"
    v-model="modal"
  >
    <v-card>
      <v-card-title>{{ $t('subscriptionExpirationDate') }}</v-card-title>
      <v-card-subtitle>{{ $t('chooseSubscriptionExpirationDate') }}</v-card-subtitle>
      <v-card-text>
        <vc-calendar
          :attributes="attributes"
          mode="single"
          @dayclick="onDayClick"
          v-model="selectedDate"
          is-expanded
          :min-date="new Date()"
        />
        <div class="pt-3 headline">
          <span>{{ $t('selectedDate') }}: </span><span>{{ $moment(selectedDate.getTime()).format('DD/MM/YYYY') }}</span>
        </div>
      </v-card-text>
      <v-alert
        type="error"
        class="mx-2"
        v-if="error.isError"
      >
        {{ error.message }}
      </v-alert>
      <v-card-actions>
        <v-btn
          :disabled="processing"
          color="red"
          text
          @click="close"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="processing"
          :loading="processing"
          @click="confirm"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SubscriptionExpirationDialog',
    props: {
      subscription: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      modal: true,
      processing: false,
      selectedDate: new Date(),
      error: {
        isError: false,
        message: null
      }
    }),
    computed: {
      attributes() {
        return [{
          key: 'today',
          hightlight: true,
          dates: new Date(),
        }]
      }
    },
    methods: {
      onDayClick(day) {
        this.selectedDate = day.date
      },
      close() {
        this.modal = false
        this.$emit('closed')
      },
      confirm() {
        this.processing = true

        if(this.selectedDate - new Date() <= 0) {
          this.error = {
            isError: true,
            message: this.$t('youMustChooseAFutureDateToCancelSubscriptionImmediatelyUseCancelFeature')
          }
          this.processing = false
          return null
        }

        let uuid = this.subscription.uuid
        let expires = Math.floor(this.selectedDate.getTime() / 1000)

        this.$store.dispatch('expireSubscription', {uuid, expires}) .then(result => {
          this.processing = false 
          this.close()
          if(result) {
            this.$store.commit('updateActionSuccess',{
              message: this.$t('success'), 
              subMessage: this.$t('expirationDateUpdated')
            })
          } else {
            this.$store.commit('updateActionError',{
              message: this.$t('unableToUpdateExpirationDate'), 
            })
          }
        }).catch(error => {
          this.processing = false
          this.close()
          this.$store.commit('updateActionError',{
              message: this.$t('unableToUpdateExpirationDate'), 
            })
        })
      }
    }
  }
</script>